import React from 'react';
import { navigate } from "gatsby"

import i18n from '../components/i18n';



class IndexPage extends React.Component {
  componentDidMount() {
    let lang = i18n.language.substring(0,2) + '/'; //add trailing slash according to our convention.
    //console.log(lang);
    //check if the path is something else:
    //console.log('Will navigate to %s.', lang);
    navigate(lang);
  
  }
  render() {
    return (
      <></>
    )
  }
}  


export default IndexPage;

